import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { formatNumber, parseFormattedNumber } from '../helpers/helper';
import Swal from 'sweetalert2';
import { API_BASE_URL } from '../helpers/base_url';

// Type definitions for the API request
interface PropertyDetailDto {
  propertyTemplateId: number;
  propertyPurposeId: number;
  propertyLocationId: number;
  paymentPlanId: number;
  paymentSubPlanId: number;
  units: number;
  amount: number;
  status: string;
  paymentStatus: string;
  paymentDate: string | null;
  referralCode: string | null;
}

interface ApplicantDetailDto {
  firstName: string;
  lastName: string;
  otherName: string;
  phone: string;
  email: string;
  title: string;
  motherMaidenName: string;
  address: string;
  birthDate: string;
  gender: string;
  maritalStatus: string;
  occupation: string;
  nationalityId: number;
}

interface NokDetailDto {
  title: string;
  firstName: string;
  lastName: string;
  otherName: string;
  phone: string;
  email: string;
  address: string;
  relationshipId: number;
}

interface ApplicationRequestData {
  application: PropertyDetailDto;
  customer: ApplicantDetailDto;
  nok: NokDetailDto;
}

function ApplicationFormGeneral() {
  // const { estate } = useParams();
  const [estates, setEstates] = useState([])
  const [estate, setEstate] = useState<any>(null);
  const [template, setTemplate] = useState<any>(null);
  const [templateInfo, setTemplateInfo] = useState<any>({});
  const [propertyTypes, setPropertyTypes] = useState<any>([]);
  const [propertyPurposes, setPropertyPurposes] = useState<any>([]);
  const [propertyLocations, setPropertyLocations] = useState<any>([]);
  const [numberofPlots, setNumberofPlots] = useState<any>([]);
  const [paymentPlans, setPaymentPlans] = useState<any>([{ id: 1, name: 'One-Time Payment' }]);
  const [paymentSubPlans, setPaymentSubPlans] = useState<any>([]);
  const [nationalities, setNationalities] = useState<any>([{ id: 1, name: 'Nigerian' }]);
  const [relationships, setRelationships] = useState<any>([{ id: 1, name: 'Husband' }]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty, touchedFields },
    reset,
    setValue,
    getValues,
    watch
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      propertyDetail: {
        estate: null,
        propertyTemplate: null,
        units: null,
        paymentPlan: null,
        paymentSubPlan: null,
        landPurpose: null,
        propertyLocation: null,
        plotSize: null,
        referralCode: null,
        price: '0.00'
      },
      applicantDetail: {
        title: null,
        firstName: null,
        lastName: null,
        otherName: null,
        gender: null,
        maritalStatus: null,
        birthDate: null,
        nationality: null,
        motherMaidenName: null,
        occupation: null,
        phone: null,
        email: null,
      },
      nokDetail: {
        title: null,
        firstName: null,
        lastName: null,
        otherName: null,
        phone: null,
        email: null,
        relationship: null,
      },
    }
  });

  // get all estates
  useEffect(() => {
    fetch(`${API_BASE_URL}/estate`)
      .then(res => res.json())
      .then(data => {
        setEstates(data?.data?.estates); // Assuming data is already the array of estates
      })
      .catch(err => console.error('Fetch error:', err));

    // fetch payment plans
    fetch(`${API_BASE_URL}/payment-plan`)
      .then(res => res.json())
      .then(data => {
        console.log('Parsed data:', data);
        setPaymentPlans(data?.data);
      })
      .catch(err => console.error('Fetch error:', err));

    // fetch property purpose
    fetch(`${API_BASE_URL}/property-purpose`)
      .then(res => res.json())
      .then(data => {
        console.log('Parsed data:', data);
        setPropertyPurposes(data?.data);
      })
      .catch(err => console.error('Fetch error:', err));

    // fetch property locations
    fetch(`${API_BASE_URL}/property-location`)
      .then(res => res.json())
      .then(data => {
        console.log('Parsed data:', data);
        setPropertyLocations(data?.data);
      })
      .catch(err => console.error('Fetch error:', err));
  }, []);

  // get estate information
  useEffect(() => {
    if (estate !== null) {
      fetch(`${API_BASE_URL}/estate?estate_id=${estate}`)
        .then(res => res.json())
        .then(data => {
          console.log('property-template info:', data.data?.name);
          setPropertyTypes(data?.data?.propertyTemplates);

          // // Pre-fill the estate name in the form
          // if (data?.data?.name) {
          //   reset({
          //     propertyDetail: { estate: data.data.name }
          //   });
          // }
        })
        .catch(err => console.error('Fetch error:', err));
    }
  }, [estate, reset]);

  useEffect(() => {
    if (template !== null) {
      fetch(`${API_BASE_URL}/property-template?property_template_id=${template}`)
        .then(res => res.json())
        .then(data => {
          console.log('property-template info:', data);
          setTemplateInfo(data?.data);

          // Pre-fill the estate name in the form
          if (data?.data) {
            reset({
              propertyDetail: { plotSize: data.data?.size }
            });
          }
          setNumberofPlots(generateNumberList(data?.data?.availablePropertiesCount))
        })
        .catch(err => console.error('Fetch error:', err));
    }
  }, [template, reset]);

  function generateNumberList(n: number) {
    // Input validation
    if (!Number.isInteger(n) || n < 0) {
      throw new Error("Input must be a non-negative integer");
    }

    // Special case for 0
    if (n === 0) {
      return [];
    }

    // Create array of numbers from 1 to n
    const result = [];
    for (let i = 1; i <= n; i++) {
      result.push(i);
    }

    return result;
  }

  useEffect(() => {
    const formValues = getValues();
    const basePrice = templateInfo?.prices?.[0]?.price || 0;
    let totalPrice = 0;
    let landPurposeCommission = 0;
    let locationCommission = 0;
    let planCommission = 0;

    // Calculate commissions regardless of units
    // Calculate Land Purpose Commission
    if (formValues.propertyDetail.landPurpose && formValues.propertyDetail.landPurpose !== 'null') {
      const landPurpose = propertyPurposes.find((pp: any) => pp.id == formValues.propertyDetail.landPurpose);
      const commission = landPurpose?.commissions?.[0]?.commission || 0;
      landPurposeCommission = basePrice * (commission / 100);
    }

    // Calculate Property Location Commission
    if (formValues.propertyDetail.propertyLocation && formValues.propertyDetail.propertyLocation !== 'null') {
      const propertyLocation = propertyLocations.find((pl: any) => pl.id == formValues.propertyDetail.propertyLocation);
      const commission = propertyLocation?.commissions?.[0]?.commission || 0;
      locationCommission = basePrice * (commission / 100);
    }

    // Calculate Payment Sub-Plan Commission
    if (formValues.propertyDetail.paymentSubPlan && formValues.propertyDetail.paymentSubPlan !== 'null') {
      const paymentSubPlan = paymentSubPlans.find((pp: any) => pp.id == formValues.propertyDetail.paymentSubPlan);
      const commission = paymentSubPlan?.commissions?.[0]?.commission || 0;
      planCommission = basePrice * (commission / 100);
    }

    // Only calculate total price if units are valid
    if (formValues.propertyDetail.units && formValues.propertyDetail.units !== 'null' && parseInt(formValues.propertyDetail.units, 10) > 0) {
      const unitCount = parseInt(formValues.propertyDetail.units, 10);
      totalPrice = basePrice * unitCount;

      // Add commissions only when units are valid
      totalPrice += landPurposeCommission + locationCommission + planCommission;
    }

    // Update form value
    setValue("propertyDetail.price", formatNumber(totalPrice));

  }, [
    templateInfo,
    watch("propertyDetail.landPurpose"),
    watch("propertyDetail.propertyLocation"),
    watch("propertyDetail.paymentSubPlan"),
    watch("propertyDetail.units"),
    propertyPurposes,
    propertyLocations,
    paymentSubPlans
  ]);

  const handleEstateChange = (event: any) => {
    console.log("🚀 ~ handlePropertyTemplateChange ~ event:", event.target.value)
    setEstate(event.target.value)
  };

  const handlePropertyTemplateChange = (event: any) => {
    console.log("🚀 ~ handlePropertyTemplateChange ~ event:", event.target.value)
    setTemplate(event.target.value)
  };

  const handlePropertyPurposeChange = (event: any) => {
    console.log("🚀 ~ handlePropertyPurposeChange ~ event:", event.target.value)
    const formValues = getValues();
    fetch(`${API_BASE_URL}/property-template?property_template_id=${template}&property_purpose_id=${formValues?.propertyDetail?.landPurpose && formValues?.propertyDetail?.landPurpose != '' ? formValues?.propertyDetail?.landPurpose : null}&property_location_id=${formValues?.propertyDetail?.propertyLocation && formValues?.propertyDetail?.propertyLocation != '' ? formValues?.propertyDetail?.propertyLocation : null}`)
      .then(res => res.json())
      .then(data => {
        console.log('property-template info:', data);
        setTemplateInfo(data?.data);

        // Pre-fill the estate name in the form
        if (data?.data?.estate?.name) {
          reset({
            propertyDetail: { estate: data.data.estate.name, propertyTemplate: data.data?.templateName, plotSize: data.data?.size }
          });
        }
        setNumberofPlots(generateNumberList(data?.data?.availablePropertiesCount))
      })
      .catch(err => console.error('Fetch error:', err));
  };

  const handlePropertyLocationChange = (event: any) => {
    const formValues = getValues();
    fetch(`${API_BASE_URL}/property-template?property_template_id=${template}&property_purpose_id=${formValues?.propertyDetail?.landPurpose && formValues?.propertyDetail?.landPurpose != '' ? formValues?.propertyDetail?.landPurpose : null}&property_location_id=${formValues?.propertyDetail?.propertyLocation && formValues?.propertyDetail?.propertyLocation != '' ? formValues?.propertyDetail?.propertyLocation : null}`)
      .then(res => res.json())
      .then(data => {
        console.log('property-template info:', data);
        setTemplateInfo(data?.data);

        // Pre-fill the estate name in the form
        if (data?.data?.estate?.name) {
          reset({
            propertyDetail: { estate: data.data.estate.name, propertyTemplate: data.data?.templateName, plotSize: data.data?.size }
          });
        }
        setNumberofPlots(generateNumberList(data?.data?.availablePropertiesCount))
      })
      .catch(err => console.error('Fetch error:', err));
  };

  const handlePaymentPlanChange = (event: any) => {
    const selectedValue = event.target.value;
    console.log("Selected units:", selectedValue);
    let paymentSubPlans = paymentPlans.find((paymentPlan: any) => {
      return paymentPlan.id == selectedValue
    }).paymentSubPlans
    console.log("🚀 ~ paymentSubPlans ~ paymentSubPlans:", paymentSubPlans)

    setPaymentSubPlans(paymentSubPlans)
  };

  const handleSubmitApplication = async (data: any) => {
    try {
      // Set loading state if needed
      // setIsSubmitting(true);

      // Transform form data to match the DTO structure expected by the API
      const applicationData: ApplicationRequestData = {
        application: {
          propertyTemplateId: templateInfo.id,
          propertyPurposeId: +data.propertyDetail.landPurpose,
          propertyLocationId: +data.propertyDetail.propertyLocation,
          paymentPlanId: +data.propertyDetail.paymentPlan,
          paymentSubPlanId: +data.propertyDetail.paymentSubPlan,
          units: Number(data.propertyDetail.units),
          amount: parseFormattedNumber(data.propertyDetail.price),
          status: 'Pending', // Default status
          paymentStatus: 'Not_Paid', // Default payment status
          paymentDate: null,
          referralCode: data.propertyDetail.referralCode,
        },
        customer: {
          firstName: data.applicantDetail.firstName,
          lastName: data.applicantDetail.lastName,
          otherName: data.applicantDetail.otherName,
          phone: data.applicantDetail.phone,
          email: data.applicantDetail.email,
          title: data.applicantDetail.title?.value || data.applicantDetail.title,
          motherMaidenName: data.applicantDetail.motherMaidenName,
          address: data.applicantDetail.address, // Not in the form but required in DTO
          birthDate: data.applicantDetail.birthDate ?
            new Date(data.applicantDetail.birthDate).toISOString().split('T')[0] : '',
          gender: data.applicantDetail.gender?.value || data.applicantDetail.gender,
          maritalStatus: data.applicantDetail.maritalStatus?.value || data.applicantDetail.maritalStatus,
          occupation: data.applicantDetail.occupation,
          nationalityId: data.applicantDetail.nationality?.id || 1, // Default to ID 1 if not provided
        },
        nok: {
          title: data.nokDetail.title,
          firstName: data.nokDetail.firstName,
          lastName: data.nokDetail.lastName,
          otherName: data.nokDetail.otherName,
          phone: data.nokDetail.phone,
          email: data.nokDetail.email,
          address: data.nokDetail.address, // Not in the form but required in DTO
          relationshipId: data.nokDetail.relationship?.id || 1, // Default to ID 1 if not provided
        }
      };
      console.log("🚀 ~ handleSubmitApplication ~ applicationData:", applicationData)

      // Make the API request
      const response = await fetch(`${API_BASE_URL}/application-request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(applicationData)
      });

      // Handle non-2xx responses
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit application');
      }

      // Handle successful response
      const result = await response.json();

      // Show success message
      Swal.fire('Application submitted successfully!');

      // Reset form
      reset();

      return result;
    } catch (error) {
      console.error('Error submitting application:', error);

      throw error;
    } finally {
      // Reset loading state
      // setIsSubmitting(false);
    }
  };

  // The main form submission handler to be used with react-hook-form
  const onSubmit = async (data: any) => {
    try {
      await handleSubmitApplication(data);
    } catch (error) {
      // Additional error handling if needed
      console.error('Form submission error:', error);
    }
  };

  const handleCancel = () => {
    // Reset form or navigate back
    reset();
  };

  return (
    <>
      <div className="full-row">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2
                style={{
                  color: "#510000",
                  fontFamily: "Nunito Sans !important",
                  fontWeight: "700",
                  fontSize: "24px",
                  lineHeight: "36px",
                  letterSpacing: "-1%",
                }}
              >
                Application Form
              </h2>
              <p
                style={{
                  fontFamily: "Nunito Sans !important",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                <strong style={{ marginRight: "5px" }}>Instructions:</strong>
                Please read carefully before filling; - Form filled wrongly will
                not be processed - All forms submitted is treated as
                confidential
              </p>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col">
              <div className="bg-white mb-30">
                <div className="row">
                  <div className="col-xl-12">
                    <form className="form-boder" onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="row">
                          <div className="col">
                            <h3
                              style={{
                                color: "#510000",
                                fontFamily: "Nunito Sans !important",
                                fontWeight: "700",
                                fontSize: "24px",
                                lineHeight: "36px",
                                letterSpacing: "-1%",
                              }}
                            >
                              Property Detail
                            </h3>
                          </div>
                        </div>

                        {/* <div className="col-lg-4 mb-20">
                          <label className="mb-2 font-fifteen font-500">
                            Estate Name
                          </label>
                          <input
                            className="form-control"
                            {...register("propertyDetail.estate", { required: true })}
                            placeholder="Estate Name"
                            type="text"
                            disabled
                          />
                          {errors.propertyDetail?.estate && <span className="text-danger">Estate name is required</span>}
                        </div> */}
                        <div className="col-lg-4 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.propertyDetail?.estate && errors.propertyDetail?.estate ? 'text-danger' : ''}`}>
                            Estate Name
                          </label>
                          <select
                            className={`form-control ${touchedFields.propertyDetail?.estate && errors.propertyDetail?.estate ? 'is-invalid' : ''}`}
                            {...register("propertyDetail.estate", {
                              required: true,
                              onChange: handleEstateChange
                            })}
                          >
                            <option value="null">Select Estate</option>
                            {estates.map((estate: any, index: number) => (
                              <option key={index} value={estate?.id}>
                                {estate?.name}
                              </option>
                            ))}
                          </select>
                          {touchedFields.propertyDetail?.estate && errors.propertyDetail?.estate && <span className="text-danger">Please seelct an estate</span>}
                        </div>

                        {/* <div className="col-lg-4 mb-20">
                          <label className="mb-2 font-fifteen font-500">
                            Property Template
                          </label>
                          <input
                            className="form-control"
                            {...register("propertyDetail.propertyTemplate")}
                            placeholder="Estate Name"
                            type="text"
                            disabled
                          />
                        </div> */}

                        <div className="col-lg-4 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.propertyDetail?.propertyTemplate && errors.propertyDetail?.propertyTemplate ? 'text-danger' : ''}`}>
                            Property Template
                          </label>
                          <select
                            className={`form-control ${touchedFields.propertyDetail?.propertyTemplate && errors.propertyDetail?.propertyTemplate ? 'is-invalid' : ''}`}
                            {...register("propertyDetail.propertyTemplate", {
                              required: true,
                              onChange: handlePropertyTemplateChange
                            })}
                          >
                            <option value="null">Select Property Template</option>
                            {propertyTypes.map((propertyType: any, index: number) => (
                              <option key={index} value={propertyType?.id}>
                                {propertyType?.templateName}
                              </option>
                            ))}
                          </select>
                          {touchedFields.propertyDetail?.propertyTemplate && errors.propertyDetail?.propertyTemplate && <span className="text-danger">Property template is required</span>}
                        </div>

                        <div className="col-lg-4 mb-20">
                          <label className="mb-2 font-fifteen font-500">
                            Plot Size (sqm)
                          </label>
                          <input
                            className="form-control"
                            {...register("propertyDetail.plotSize", { required: true })}
                            placeholder="Plot Size"
                            type="text"
                            disabled
                          />
                          {errors.propertyDetail?.plotSize && <span className="text-danger">Plot size is required</span>}
                        </div>

                        <div className="col-lg-4 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.propertyDetail?.landPurpose && errors.propertyDetail?.landPurpose ? 'text-danger' : ''}`}>
                            Land Purpose
                          </label>
                          <select
                            className={`form-control ${touchedFields.propertyDetail?.landPurpose && errors.propertyDetail?.landPurpose ? 'is-invalid' : ''}`}
                            {...register("propertyDetail.landPurpose", { required: true, onChange: handlePropertyPurposeChange })}
                          >
                            <option value="null">Select Land Purpose</option>
                            {propertyPurposes.map((propertyPurpose: any, index: number) => (
                              <option key={index} value={propertyPurpose?.id}>
                                {propertyPurpose?.purpose}
                              </option>
                            ))}
                          </select>
                          {touchedFields.propertyDetail?.landPurpose && errors.propertyDetail?.landPurpose && <span className="text-danger">Land purpose is required</span>}
                        </div>

                        <div className="col-lg-4 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.propertyDetail?.propertyLocation && errors.propertyDetail?.propertyLocation ? 'text-danger' : ''}`}>
                            Property Location
                          </label>
                          <select
                            className={`form-control ${touchedFields.propertyDetail?.propertyLocation && errors.propertyDetail?.propertyLocation ? 'is-invalid' : ''}`}
                            {...register("propertyDetail.propertyLocation", { required: true, onChange: handlePropertyLocationChange })}
                          >
                            <option value="null">Select Property Location</option>
                            {propertyLocations.map((propertyPurpose: any, index: number) => (
                              <option key={index} value={propertyPurpose?.id}>
                                {propertyPurpose?.name}
                              </option>
                            ))}
                          </select>
                          {touchedFields.propertyDetail?.propertyLocation && errors.propertyDetail?.propertyLocation && <span className="text-danger">Property location is required</span>}
                        </div>

                        <div className="col-lg-4 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.propertyDetail?.units && errors.propertyDetail?.units ? 'text-danger' : ''}`}>
                            Number of Units
                          </label>
                          <select
                            className={`form-control ${touchedFields.propertyDetail?.units && errors.propertyDetail?.units ? 'is-invalid' : ''}`}
                            {...register("propertyDetail.units", {
                              required: true,
                              // onChange: handleUnitsChange 
                            })}
                          >
                            <option value="null">Select Number of Units</option>
                            {numberofPlots.map((numberofPlot: any, index: number) => (
                              <option key={index} value={numberofPlot}>
                                {numberofPlot}
                              </option>
                            ))}
                          </select>
                          {touchedFields.propertyDetail?.units && errors.propertyDetail?.units && <span className="text-danger">Number of plots is required</span>}
                        </div>

                        <div className="col-lg-4 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.propertyDetail?.paymentPlan && errors.propertyDetail?.paymentPlan ? 'text-danger' : ''}`}>
                            Payment Plan
                          </label>
                          <select
                            className={`form-control ${touchedFields.propertyDetail?.paymentPlan && errors.propertyDetail?.paymentPlan ? 'is-invalid' : ''}`}
                            {...register("propertyDetail.paymentPlan", { required: true, onChange: handlePaymentPlanChange })}
                          >
                            <option value="null">Select Payment Plan</option>
                            {paymentPlans.map((paymentPlan: any, index: number) => (
                              <option key={index} value={paymentPlan?.id}>
                                {paymentPlan?.name}
                              </option>
                            ))}
                          </select>
                          {touchedFields.propertyDetail?.paymentPlan && errors.propertyDetail?.paymentPlan && <span className="text-danger">Land purpose is required</span>}
                        </div>

                        {paymentSubPlans.length > 0 && <div className="col-lg-4 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.propertyDetail?.paymentSubPlan && errors.propertyDetail?.paymentSubPlan ? 'text-danger' : ''}`}>
                            Payment Package
                          </label>
                          <select
                            className={`form-control ${touchedFields.propertyDetail?.paymentSubPlan && errors.propertyDetail?.paymentSubPlan ? 'is-invalid' : ''}`}
                            {...register("propertyDetail.paymentSubPlan", { required: true })}
                          >
                            <option value="null">Select Payment Plan</option>
                            {paymentSubPlans.map((paymentSubPlan: any, index: number) => (
                              <option key={index} value={paymentSubPlan?.id}>
                                {paymentSubPlan?.name}
                              </option>
                            ))}
                          </select>
                          {touchedFields.propertyDetail?.paymentSubPlan && errors.propertyDetail?.paymentSubPlan && <span className="text-danger">Land purpose is required</span>}
                        </div>}



                        <div className="col-lg-4 mb-20">
                          <label className="mb-2 font-fifteen font-500">
                            Price
                          </label>
                          <input
                            className="form-control"
                            {...register("propertyDetail.price", { required: true })}
                            placeholder="Estate Name"
                            type="text"
                            disabled
                          />
                          {errors.propertyDetail?.price && <span className="text-danger">Estate name is required</span>}
                        </div>

                        {/* <div className="col-lg-4 mb-20">
                          <label className="mb-2 font-fifteen font-500">
                            Price
                          </label>
                          <input
                            className="form-control"
                            {...register("propertyDetail.price", { required: true })}
                            placeholder="Estate Name"
                            type="text"
                            disabled
                          />
                          {errors.propertyDetail?.price && <span className="text-danger">Estate name is required</span>}
                        </div> */}

                        <div className="row mt-5">
                          <div className="col">
                            <h3
                              style={{
                                color: "#510000",
                                fontFamily: "Nunito Sans !important",
                                fontWeight: "700",
                                fontSize: "24px",
                                lineHeight: "36px",
                                letterSpacing: "-1%",
                              }}
                            >
                              Individual Application Form
                            </h3>
                          </div>
                        </div>

                        <div className="col-lg-3 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.applicantDetail?.title && errors.applicantDetail?.title ? 'text-danger' : ''}`}>
                            Title
                          </label>
                          <select
                            className={`form-control ${touchedFields.applicantDetail?.title && errors.applicantDetail?.title ? 'is-invalid' : ''}`}
                            {...register("applicantDetail.title", { required: true })}
                          >
                            <option value="">Select Title</option>
                            <option value="Mr.">Mr.</option>
                            <option value="Mrs.">Mrs.</option>
                          </select>
                          {touchedFields.applicantDetail?.title && errors.applicantDetail?.title && <span className="text-danger">Title is required</span>}
                        </div>

                        <div className="col-lg-3 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.applicantDetail?.firstName && errors.applicantDetail?.firstName ? 'text-danger' : ''}`}>
                            First Name
                          </label>
                          <input
                            className={`form-control ${errors.applicantDetail?.firstName ? 'is-invalid' : ''}`}
                            {...register("applicantDetail.firstName", {
                              required: "First name is required",
                              minLength: { value: 2, message: "First name must be at least 2 characters" }
                            })}
                            placeholder="First Name"
                            type="text"
                            aria-invalid={errors.applicantDetail?.firstName ? "true" : "false"}
                          />
                          {touchedFields.applicantDetail?.firstName && errors.applicantDetail?.firstName && <span className="text-danger">{errors.applicantDetail?.firstName.message}</span>}
                        </div>

                        <div className="col-lg-3 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.applicantDetail?.lastName && errors.applicantDetail?.lastName ? 'text-danger' : ''}`}>
                            Last Name
                          </label>
                          <input
                            className={`form-control ${errors.applicantDetail?.lastName ? 'is-invalid' : ''}`}
                            {...register("applicantDetail.lastName", {
                              required: "First name is required",
                              minLength: { value: 2, message: "Last name must be at least 2 characters" }
                            })}
                            placeholder="Last Name"
                            type="text"
                            aria-invalid={errors.applicantDetail?.lastName ? "true" : "false"}
                          />
                          {touchedFields.applicantDetail?.lastName && errors.applicantDetail?.lastName && <span className="text-danger">{errors.applicantDetail?.lastName.message}</span>}
                        </div>

                        <div className="col-lg-3 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.applicantDetail?.otherName && errors.applicantDetail?.otherName ? 'text-danger' : ''}`}>
                            Other Name
                          </label>
                          <input
                            className={`form-control ${errors.applicantDetail?.otherName ? 'is-invalid' : ''}`}
                            {...register("applicantDetail.otherName", {
                              // required: "First name is required",
                              minLength: { value: 2, message: "Other name must be at least 2 characters" }
                            })}
                            placeholder="Other Name"
                            type="text"
                            aria-invalid={errors.applicantDetail?.otherName ? "true" : "false"}
                          />
                          {touchedFields.applicantDetail?.otherName && errors.applicantDetail?.otherName && <span className="text-danger">{errors.applicantDetail?.otherName.message}</span>}
                        </div>

                        <div className="col-lg-3 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.applicantDetail?.gender && errors.applicantDetail?.gender ? 'text-danger' : ''}`}>
                            Gender
                          </label>
                          <select
                            className={`form-control ${touchedFields.applicantDetail?.gender && errors.applicantDetail?.gender ? 'is-invalid' : ''}`}
                            {...register("applicantDetail.gender", { required: true })}
                          >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                          {touchedFields.applicantDetail?.gender && errors.applicantDetail?.gender && <span className="text-danger">Gender is required</span>}
                        </div>

                        <div className="col-lg-3 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.applicantDetail?.maritalStatus && errors.applicantDetail?.maritalStatus ? 'text-danger' : ''}`}>
                            Marital Status
                          </label>
                          <select
                            className={`form-control ${touchedFields.applicantDetail?.maritalStatus && errors.applicantDetail?.maritalStatus ? 'is-invalid' : ''}`}
                            {...register("applicantDetail.maritalStatus", { required: true })}
                          >
                            <option value="">Select Marital Status</option>
                            <option value="single">Single</option>
                            <option value="married">Married</option>
                          </select>
                          {touchedFields.applicantDetail?.maritalStatus && errors.applicantDetail?.maritalStatus && <span className="text-danger">Title is required</span>}
                        </div>

                        <div className="col-lg-3 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.applicantDetail?.birthDate && errors.applicantDetail?.birthDate ? 'text-danger' : ''}`}>
                            Date of Birth
                          </label>
                          <input
                            className={`form-control ${errors.applicantDetail?.birthDate ? 'is-invalid' : ''}`}
                            {...register("applicantDetail.birthDate", {
                              required: "First name is required",
                            })}
                            placeholder="Other Name"
                            type="date"
                            aria-invalid={errors.applicantDetail?.birthDate ? "true" : "false"}
                          />
                          {touchedFields.applicantDetail?.birthDate && errors.applicantDetail?.birthDate && <span className="text-danger">{errors.applicantDetail?.birthDate.message}</span>}
                        </div>

                        <div className="col-lg-4 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.applicantDetail?.nationality && errors.applicantDetail?.nationality ? 'text-danger' : ''}`}>
                            Nationality
                          </label>
                          <select
                            className={`form-control ${touchedFields.applicantDetail?.nationality && errors.applicantDetail?.nationality ? 'is-invalid' : ''}`}
                            {...register("applicantDetail.nationality", { required: true })}
                          >
                            <option value="">Select Nationality</option>
                            {nationalities.map((nationality: any, index: number) => (
                              <option key={index} value={nationality?.id}>
                                {nationality?.name}
                              </option>
                            ))}
                          </select>
                          {touchedFields.applicantDetail?.nationality && errors.applicantDetail?.nationality && <span className="text-danger">Land purpose is required</span>}
                        </div>

                        <div className="col-lg-3 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.applicantDetail?.motherMaidenName && errors.applicantDetail?.motherMaidenName ? 'text-danger' : ''}`}>
                            Mother Maiden Name
                          </label>
                          <input
                            className={`form-control ${errors.applicantDetail?.motherMaidenName ? 'is-invalid' : ''}`}
                            {...register("applicantDetail.motherMaidenName", {
                              required: "First name is required",
                              minLength: { value: 2, message: "Mother Maiden Name must be at least 2 characters" }
                            })}
                            placeholder="Mother Maiden Name"
                            type="text"
                            aria-invalid={errors.applicantDetail?.motherMaidenName ? "true" : "false"}
                          />
                          {touchedFields.applicantDetail?.motherMaidenName && errors.applicantDetail?.motherMaidenName && <span className="text-danger">{errors.applicantDetail?.motherMaidenName.message}</span>}
                        </div>

                        <div className="col-lg-3 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.applicantDetail?.occupation && errors.applicantDetail?.occupation ? 'text-danger' : ''}`}>
                            Occupation
                          </label>
                          <input
                            className={`form-control ${errors.applicantDetail?.occupation ? 'is-invalid' : ''}`}
                            {...register("applicantDetail.occupation", {
                              required: "First name is required",
                              minLength: { value: 2, message: "Occupation must be at least 2 characters" }
                            })}
                            placeholder="Occupation"
                            type="text"
                            aria-invalid={errors.applicantDetail?.occupation ? "true" : "false"}
                          />
                          {touchedFields.applicantDetail?.occupation && errors.applicantDetail?.occupation && <span className="text-danger">{errors.applicantDetail?.occupation.message}</span>}
                        </div>

                        <div className="col-lg-3 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.applicantDetail?.phone && errors.applicantDetail?.phone ? 'text-danger' : ''}`}>
                            Phone Number
                          </label>
                          <input
                            className={`form-control ${errors.applicantDetail?.phone ? 'is-invalid' : ''}`}
                            {...register("applicantDetail.phone", {
                              required: "First name is required",
                              minLength: { value: 2, message: "Phone Number must be at least 2 characters" }
                            })}
                            placeholder="Phone Number"
                            type="text"
                            aria-invalid={errors.applicantDetail?.phone ? "true" : "false"}
                          />
                          {touchedFields.applicantDetail?.phone && errors.applicantDetail?.phone && <span className="text-danger">{errors.applicantDetail?.phone.message}</span>}
                        </div>

                        <div className="col-lg-3 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.applicantDetail?.email && errors.applicantDetail?.email ? 'text-danger' : ''}`}>
                            Email
                          </label>
                          <input
                            className={`form-control ${errors.applicantDetail?.email ? 'is-invalid' : ''}`}
                            {...register("applicantDetail.email", {
                              required: "First name is required",
                              minLength: { value: 2, message: "Email must be at least 2 characters" }
                            })}
                            placeholder="Email"
                            type="text"
                            aria-invalid={errors.applicantDetail?.email ? "true" : "false"}
                          />
                          {touchedFields.applicantDetail?.email && errors.applicantDetail?.email && <span className="text-danger">{errors.applicantDetail?.email.message}</span>}
                        </div>

                        <div className="row mt-5">
                          <div className="col">
                            <h3
                              style={{
                                color: "#510000",
                                fontFamily: "Nunito Sans !important",
                                fontWeight: "700",
                                fontSize: "24px",
                                lineHeight: "36px",
                                letterSpacing: "-1%",
                              }}
                            >
                              Next Of Kin Information
                            </h3>
                          </div>
                        </div>

                        <div className="col-lg-3 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.nokDetail?.title && errors.nokDetail?.title ? 'text-danger' : ''}`}>
                            Title
                          </label>
                          <select
                            className={`form-control ${touchedFields.nokDetail?.title && errors.nokDetail?.title ? 'is-invalid' : ''}`}
                            {...register("nokDetail.title", { required: true })}
                          >
                            <option value="">Select Title</option>
                            <option value="Mr.">Mr.</option>
                            <option value="Mrs.">Mrs.</option>
                          </select>
                          {touchedFields.nokDetail?.title && errors.nokDetail?.title && <span className="text-danger">Title is required</span>}
                        </div>

                        <div className="col-lg-3 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.nokDetail?.firstName && errors.nokDetail?.firstName ? 'text-danger' : ''}`}>
                            First Name
                          </label>
                          <input
                            className={`form-control ${errors.nokDetail?.firstName ? 'is-invalid' : ''}`}
                            {...register("nokDetail.firstName", {
                              required: "First name is required",
                              minLength: { value: 2, message: "First name must be at least 2 characters" }
                            })}
                            placeholder="First Name"
                            type="text"
                            aria-invalid={errors.nokDetail?.firstName ? "true" : "false"}
                          />
                          {touchedFields.nokDetail?.firstName && errors.nokDetail?.firstName && <span className="text-danger">{errors.nokDetail?.firstName.message}</span>}
                        </div>

                        <div className="col-lg-3 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.nokDetail?.lastName && errors.nokDetail?.lastName ? 'text-danger' : ''}`}>
                            Last Name
                          </label>
                          <input
                            className={`form-control ${errors.nokDetail?.lastName ? 'is-invalid' : ''}`}
                            {...register("nokDetail.lastName", {
                              required: "First name is required",
                              minLength: { value: 2, message: "Last name must be at least 2 characters" }
                            })}
                            placeholder="Last Name"
                            type="text"
                            aria-invalid={errors.nokDetail?.lastName ? "true" : "false"}
                          />
                          {touchedFields.nokDetail?.lastName && errors.nokDetail?.lastName && <span className="text-danger">{errors.nokDetail?.lastName.message}</span>}
                        </div>

                        <div className="col-lg-3 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.nokDetail?.otherName && errors.nokDetail?.otherName ? 'text-danger' : ''}`}>
                            Other Name
                          </label>
                          <input
                            className={`form-control ${errors.nokDetail?.otherName ? 'is-invalid' : ''}`}
                            {...register("nokDetail.otherName", {
                              // required: "First name is required",
                              minLength: { value: 2, message: "Other name must be at least 2 characters" }
                            })}
                            placeholder="Other Name"
                            type="text"
                            aria-invalid={errors.nokDetail?.otherName ? "true" : "false"}
                          />
                          {touchedFields.nokDetail?.otherName && errors.nokDetail?.otherName && <span className="text-danger">{errors.nokDetail?.otherName.message}</span>}
                        </div>

                        <div className="col-lg-3 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.nokDetail?.phone && errors.nokDetail?.phone ? 'text-danger' : ''}`}>
                            Phone Number
                          </label>
                          <input
                            className={`form-control ${errors.nokDetail?.phone ? 'is-invalid' : ''}`}
                            {...register("nokDetail.phone", {
                              required: "First name is required",
                              minLength: { value: 2, message: "Phone Number must be at least 2 characters" }
                            })}
                            placeholder="Phone Number"
                            type="text"
                            aria-invalid={errors.nokDetail?.phone ? "true" : "false"}
                          />
                          {touchedFields.nokDetail?.phone && errors.nokDetail?.phone && <span className="text-danger">{errors.nokDetail?.phone.message}</span>}
                        </div>

                        <div className="col-lg-3 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.nokDetail?.email && errors.nokDetail?.email ? 'text-danger' : ''}`}>
                            Email
                          </label>
                          <input
                            className={`form-control ${errors.nokDetail?.email ? 'is-invalid' : ''}`}
                            {...register("nokDetail.email", {
                              required: "First name is required",
                              minLength: { value: 2, message: "Email must be at least 2 characters" }
                            })}
                            placeholder="Email"
                            type="text"
                            aria-invalid={errors.nokDetail?.email ? "true" : "false"}
                          />
                          {touchedFields.nokDetail?.email && errors.nokDetail?.email && <span className="text-danger">{errors.nokDetail?.email.message}</span>}
                        </div>

                        <div className="col-lg-4 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.nokDetail?.relationship && errors.nokDetail?.relationship ? 'text-danger' : ''}`}>
                            Relationship
                          </label>
                          <select
                            className={`form-control ${touchedFields.nokDetail?.relationship && errors.nokDetail?.relationship ? 'is-invalid' : ''}`}
                            {...register("nokDetail.relationship", { required: true })}
                          >
                            <option value="">Select Relationship</option>
                            {relationships.map((relationship: any, index: number) => (
                              <option key={index} value={relationship?.id}>
                                {relationship?.name}
                              </option>
                            ))}
                          </select>
                          {touchedFields.nokDetail?.relationship && errors.nokDetail?.relationship && <span className="text-danger">Land purpose is required</span>}
                        </div>

                        <div className="row mt-5">
                          <div className="col">
                            <h3
                              style={{
                                color: "#510000",
                                fontFamily: "Nunito Sans !important",
                                fontWeight: "700",
                                fontSize: "24px",
                                lineHeight: "36px",
                                letterSpacing: "-1%",
                              }}
                            >
                              Referral Information
                            </h3>
                          </div>
                        </div>

                        <div className="col-lg-3 mb-20">
                          <label className={`mb-2 font-fifteen font-500 ${touchedFields.propertyDetail?.referralCode && errors.propertyDetail?.referralCode ? 'text-danger' : ''}`}>
                            Referral Code
                          </label>
                          <input
                            className={`form-control ${errors.propertyDetail?.referralCode ? 'is-invalid' : ''}`}
                            {...register("propertyDetail.referralCode", {
                              maxLength: { value: 11, message: "Referral code must be 11 digits long" }
                            })}
                            placeholder="Referral Code"
                            type="text"
                            aria-invalid={errors.propertyDetail?.referralCode ? "true" : "false"}
                          />
                          {touchedFields.propertyDetail?.referralCode && errors.propertyDetail?.referralCode && <span className="text-danger">{errors.propertyDetail?.referralCode.message}</span>}
                        </div>

                        <div className="row mt-5">
                          <div className="col">
                            <h3
                              style={{
                                color: "#510000",
                                fontFamily: "Nunito Sans !important",
                                fontWeight: "700",
                                fontSize: "24px",
                                lineHeight: "36px",
                                letterSpacing: "-1%",
                              }}
                            >
                              Declaration
                            </h3>
                          </div>
                        </div>

                        <p>
                          <span className="font-500">NOTE :</span> I/We do hereby
                          solemnly and sincerely declare that the information
                          given on this application is true and correct to the
                          best of my/our knowledge.
                        </p>

                        <div className="col-lg-12 mt-5 mb-20 text-center">
                          <button
                            type="button"
                            className="btn mr-3"
                            style={{ backgroundColor: '#000', color: '#fff' }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          {/* <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginLeft: '10px' }}
                          >
                            Submit Application
                          </button> */}
                          <button
                            type="submit"
                            className={`btn btn-primary ${(!isDirty || !isValid) ? 'opacity-50' : ''}`}
                            style={{ marginLeft: '10px' }}
                            disabled={!isDirty || !isValid}
                          >
                            Submit Application
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplicationFormGeneral;