import { useEffect, useState } from 'react';
import './other-listing.css'
import { API_BASE_URL } from '../../helpers/base_url';
import { formatNumber } from '../../helpers/helper';

function OtherListing() {
    const [estates, setEstates] = useState([])
    useEffect(() => {
        fetch(`${API_BASE_URL}/estate`)
            .then(res => res.json())
            .then(data => {
                console.log('Parsed data:', data);
                setEstates(data?.data?.estates); // Assuming data is already the array of estates
            })
            .catch(err => console.error('Fetch error:', err));
    }, []);
    return (
        <div className="full-row bg-light">
            <div className="container">
                <div className="row mb-5">
                    <span className="text-center other-listing-sub-header-text">
                        Want More?
                    </span>
                    <div className="col-12">
                        <h2 className="text-center header-title-text">
                            Other Listings
                        </h2>
                    </div>
                </div>

                {/* Property Grid */}
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                    {estates.map((item: any) => (
                        <div
                            key={item}
                            className="col"
                        >
                            <div
                                className="bg-white h-100"
                                style={{
                                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                    padding: "15px",
                                    borderRadius: "10px",
                                }}
                            >
                                <div className="property-grid-1 property-block transation-this h-100 d-flex flex-column">
                                    <div
                                        className="overflow-hidden position-relative transation thumbnail-img bg-secondary hover-img-zoom mb-3"
                                        style={{ borderRadius: "5px" }}
                                    >
                                        <a href="property-single-v1.html">
                                            <img
                                                src={item?.imageUrl}
                                                style={{ width: "386px", height: "258px", }}
                                                alt="Property"
                                                className="img-fluid w-100"
                                            />
                                        </a>
                                    </div>

                                    <div className="property_text flex-grow-1 pt-2 px-2">
                                        <div className="d-flex flex-column gap-2">
                                            <h3 className="listing-title-2">
                                                {item?.name}
                                            </h3>
                                            <p className="listing-description">
                                                <div dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                                            </p>
                                            <div className="d-flex flex-column gap-2">
                                                <span className="listing-price">
                                                    Starting At: {formatNumber(item.propertyTemplates.sort((a: any, b: any) => b.id - a.id)[0]?.prices[0]?.price) ?? 0}
                                                </span>
                                                <div className="d-flex flex-column gap-1">
                                                    <span className="d-flex align-items-center listing-description">
                                                        <i className={`fas fa-map-marker-alt me-2`}></i>
                                                        Awka, Anambra State
                                                    </span>
                                                    <span className="d-flex align-items-center listing-description">
                                                        <i className={`fas fa-map-marker-alt me-2`}></i>
                                                        Title: Govt Approval
                                                    </span>
                                                    <span className="d-flex align-items-center listing-description">
                                                        <i className={`fas fa-map-marker-alt me-2`}></i>
                                                        Sizes: {item.propertyTemplates.map((item: any) => {
                                                            return `${item.size} sqm`
                                                        }).join(", ") ?? "---"}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center post-meta mt-3 py-2 px-2">
                                        <div className="view-details ms-auto">
                                            <a style={{ cursor: "pointer", textDecoration: 'none' }} href={`estates/${item?.id}`}><span
                                                className="d-flex align-items-center gap-2 listing-view-more"
                                            >
                                                Click to see more details
                                                <i className="fas fa-arrow-right"></i>
                                            </span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Call to Action Button */}
                <div className="text-center mt-5">
                    <a
                        href="/buy-land"
                        className="btn btn-primary"
                        style={{
                            borderRadius: "60px",
                            padding: "0.5rem 2rem",
                            fontSize: "clamp(14px, 4vw, 16px)"
                        }}
                    >
                        Buy Now
                    </a>
                </div>
            </div>
        </div>
    )
}

export default OtherListing