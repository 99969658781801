import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './estate-listing.css'
import { useEffect, useState } from "react";
import { API_BASE_URL } from "../../helpers/base_url";
import { formatNumber } from "../../helpers/helper";
function EstateListing() {
    const [estates, setEstates] = useState([])
    useEffect(() => {
        fetch(`${API_BASE_URL}/estate`)
            .then(res => res.json())
            .then(data => {
                console.log('Parsed data:', data);
                setEstates(data?.data?.estates); // Assuming data is already the array of estates
            })
            .catch(err => console.error('Fetch error:', err));
    }, []);
    // Slider settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        // slidesToShow: 3,
        // slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
    };
    return (
        // <div className="full-row container-padding bg-light">
        <div className="full-row bg-light">
            <div className="container">
                {/* Section Title */}
                <div className="row mb-5">
                    <div className="col-12">
                        <h2 className="text-center header-text">
                            Our Estates
                        </h2>
                    </div>
                </div>

                {/* Property Grid */}
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                    {estates.map((item: any) => (
                        <div
                            key={item}
                            className="col"
                        >
                            <div
                                className="bg-white h-100"
                                style={{
                                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                    padding: "15px",
                                    borderRadius: "10px",
                                }}
                            >
                                <div className="property-grid-1 property-block transation-this h-100 d-flex flex-column">
                                    <div
                                        className="overflow-hidden position-relative transation thumbnail-img bg-secondary hover-img-zoom mb-3"
                                        style={{ borderRadius: "5px" }}
                                    >
                                        <a href="property-single-v1.html">
                                            <img
                                                src={item?.imageUrl}
                                                style={{ width: "386px", height: "258px", }}
                                                alt="Property"
                                                className="img-fluid w-100"
                                            />
                                        </a>
                                    </div>

                                    <div className="property_text flex-grow-1 pt-2 px-2">
                                        <div className="d-flex flex-column gap-2">
                                            <h3 className="listing-title-2">
                                                {item?.name}
                                            </h3>
                                            <p className="listing-description">
                                                <div dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                                            </p>
                                            <div className="d-flex flex-column gap-2">
                                                <span className="listing-price">
                                                    Starting At: {formatNumber(item.propertyTemplates.sort((a: any, b: any) => b.id - a.id)[0]?.prices[0]?.price) ?? 0}
                                                </span>
                                                <div className="d-flex flex-column gap-1">
                                                    <span className="d-flex align-items-center listing-description">
                                                        <i className={`fas fa-map-marker-alt me-2`}></i>
                                                        Awka, Anambra State
                                                    </span>
                                                    <span className="d-flex align-items-center listing-description">
                                                        <i className={`fas fa-map-marker-alt me-2`}></i>
                                                        Title: Govt Approval
                                                    </span>
                                                    <span className="d-flex align-items-center listing-description">
                                                        <i className={`fas fa-map-marker-alt me-2`}></i>
                                                        Sizes: {item.propertyTemplates.map((item: any) => {
                                                            return `${item.size} sqm`
                                                        }).join(", ") ?? "---"}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center post-meta mt-3 py-2 px-2">
                                        <div className="view-details ms-auto">
                                            <a style={{ cursor: "pointer", textDecoration: 'none' }} href={`estates/${item?.id}`}><span
                                                className="d-flex align-items-center gap-2 listing-view-more"
                                            >
                                                Click to see more details
                                                <i className="fas fa-arrow-right"></i>
                                            </span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* <div className="">
                    <Slider {...settings} slidesToShow={3} slidesToScroll={1}
                        responsive={[
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: 3,
                                    slidesToScroll: 1
                                }
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 1
                                }
                            },
                            {
                                breakpoint: 576,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                }
                            }
                        ]}
                    >
                        {estates.map((item: any, index: number) => (
                            <div className="row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                                <div
                                    key={item.id || index}
                                    className="col px-2"
                                >
                                    <div
                                        className="bg-white h-100"
                                        style={{
                                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                            padding: "15px",
                                            borderRadius: "10px",
                                        }}
                                    >
                                        <div className="property-grid-1 property-block transation-this h-100 d-flex flex-column">
                                            <div
                                                className="overflow-hidden position-relative transation thumbnail-img bg-secondary hover-img-zoom mb-3"
                                                style={{ borderRadius: "5px", aspectRatio: "16/9" }}
                                            >
                                                <a href={`estates/${item?.id}`}>
                                                    <img
                                                        src={item?.imageUrl}
                                                        alt="Property"
                                                        className="img-fluid w-100 h-100 object-fit-cover"
                                                        style={{ objectPosition: "center" }}
                                                    />
                                                </a>
                                            </div>

                                            <div className="property_text flex-grow-1 pt-2 px-2">
                                                <div className="d-flex flex-column gap-2">
                                                    <h3 className="listing-title-2">
                                                        {item?.name}
                                                    </h3>
                                                    <div className="listing-description" dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                                                    <div className="d-flex flex-column gap-2">
                                                        <span className="listing-price">
                                                            Starting At: {formatNumber(item.propertyTemplates?.sort((a: any, b: any) => b.id - a.id)[0]?.prices[0]?.price) ?? 0}
                                                        </span>
                                                        <div className="d-flex flex-column gap-1">
                                                            <span className="d-flex align-items-center listing-description">
                                                                <i className={`fas fa-map-marker-alt me-2`}></i>
                                                                Awka, Anambra State
                                                            </span>
                                                            <span className="d-flex align-items-center listing-description">
                                                                <i className={`fas fa-map-marker-alt me-2`}></i>
                                                                Title: Govt Approval
                                                            </span>
                                                            <span className="d-flex align-items-center listing-description">
                                                                <i className={`fas fa-map-marker-alt me-2`}></i>
                                                                Sizes: {item.propertyTemplates?.map((template: any) => {
                                                                    return `${template.size} sqm`
                                                                }).join(", ") ?? "---"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center post-meta mt-3 py-2 px-2">
                                                <div className="view-details ms-auto">
                                                    <a style={{ cursor: "pointer", textDecoration: 'none' }} href={`estates/${item?.id}`}>
                                                        <span className="d-flex align-items-center gap-2 listing-view-more">
                                                            Click to see more details
                                                            <i className="fas fa-arrow-right"></i>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div> */}


                {/* <div className="property-slider-container">
                    <Slider
                        {...settings}
                        slidesToShow={3}
                        slidesToScroll={1}
                        infinite={true}
                        speed={500}
                        dots={true}
                        arrows={true}
                        responsive={[
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 1
                                }
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                }
                            }
                        ]}
                    >
                        {estates.map((item: any, index: number) => (
                            <div
                                key={item.id || index}
                                className="property-slide px-2"
                            >
                                <div
                                    className="bg-white h-100"
                                    style={{
                                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                        padding: "15px",
                                        borderRadius: "10px",
                                        margin: "10px"
                                    }}
                                >
                                    <div className="property-grid-1 property-block transation-this h-100 d-flex flex-column">
                                        <div
                                            className="overflow-hidden position-relative transation thumbnail-img bg-secondary hover-img-zoom mb-3"
                                            style={{ borderRadius: "5px", aspectRatio: "16/9" }}
                                        >
                                            <a href={`estates/${item?.id}`}>
                                                <img
                                                    src={item?.imageUrl}
                                                    alt="Property"
                                                    className="img-fluid w-100 h-100 object-fit-cover"
                                                    style={{ objectPosition: "center" }}
                                                />
                                            </a>
                                        </div>

                                        <div className="property_text flex-grow-1 pt-2 px-2">
                                            <div className="d-flex flex-column gap-2">
                                                <h3 className="listing-title-2">
                                                    {item?.name}
                                                </h3>
                                                <div className="listing-description" dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                                                <div className="d-flex flex-column gap-2">
                                                    <span className="listing-price">
                                                        Starting At: {formatNumber(item.propertyTemplates.sort((a: any, b: any) => b.id - a.id)[0]?.prices[0]?.price) ?? 0}
                                                    </span>
                                                    <div className="d-flex flex-column gap-1">
                                                        <span className="d-flex align-items-center listing-description">
                                                            <i className={`fas fa-map-marker-alt me-2`}></i>
                                                            Awka, Anambra State
                                                        </span>
                                                        <span className="d-flex align-items-center listing-description">
                                                            <i className={`fas fa-map-marker-alt me-2`}></i>
                                                            Title: Govt Approval
                                                        </span>
                                                        <span className="d-flex align-items-center listing-description">
                                                            <i className={`fas fa-map-marker-alt me-2`}></i>
                                                            Sizes: {item.propertyTemplates.map((template: any) => {
                                                                return `${template.size} sqm`
                                                            }).join(", ") ?? "---"}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center post-meta mt-3 py-2 px-2">
                                            <div className="view-details ms-auto">
                                                <a style={{ cursor: "pointer", textDecoration: 'none' }} href={`estates/${item?.id}`}>
                                                    <span className="d-flex align-items-center gap-2 listing-view-more">
                                                        Click to see more details
                                                        <i className="fas fa-arrow-right"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div> */}



                {/* Call to Action Button */}
                <div className="text-center mt-5">
                    <a
                        href="/buy-land"
                        className="btn btn-primary"
                        style={{
                            borderRadius: "60px",
                            padding: "0.5rem 2rem",
                            fontSize: "clamp(14px, 4vw, 16px)"
                        }}
                    >
                        Buy Now
                    </a>
                </div>
            </div>
        </div>
    )
}

export default EstateListing