import './affiliate-program-section.css';
function AffiliateProgramSection() {
    return (
        <><div className="full-row">
            <div className="container">
                <div className="row">
                    <div className="col mb-5">
                        <h2
                            className="down-line w-75 mx-auto mb-4 text-center w-sm-100 header-title-text"
                        >
                            Looking to earn six figures from the comfort of your home?
                        </h2>
                        <div className='text-center'>
                            <img
                                src="assets/images/affiliate-program/affiliate-program.png"
                                alt="Image Not Found!"
                            />
                        </div>
                        <div style={{ marginTop: "2rem", textAlign: "center" }}>
                            <a
                                href="affiliate-program"
                                className="btn btn-lg btn-primary add-listing-btn nav-link-style"
                                style={{
                                    color: "#fff",
                                    marginLeft: "1rem",
                                    borderRadius: "60px",
                                    background: "#510000",
                                }}
                            >
                                Become an affiliate
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div></>
    )
}

export default AffiliateProgramSection