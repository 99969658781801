import { useEffect, useState } from "react";
import { API_BASE_URL } from "../../helpers/base_url";
import './header.css'
// import logo from './assets/images/logo/cadland-logo.png'
import logo from '../../imgs/logos/cadland-logo.png'

function Header() {
  const [estates, setEstates] = useState([])
  useEffect(() => {
    fetch(`${API_BASE_URL}/estate`)
      .then(res => res.json())
      .then(data => {
        console.log('Parsed data:', data);
        setEstates(data?.data?.estates); // Assuming data is already the array of estates
      })
      .catch(err => console.error('Fetch error:', err));
  }, []);

  return (
    <>
      <header
        className="transparent-header nav-on-banner fixed-bg-dark"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="main-nav py-2 xs-p-0">
          <div className="container">
            <p className="motto">FOR | THE | FUTURE</p>
            <div className="row">
              <div className="col">
                <nav className="navbar navbar-expand-lg nav-black nav-primary-hover nav-line-active">
                  <a className="navbar-brand" href="/">
                    <img
                      className="nav-logo"
                      src={logo}
                      // src="./logos/cadland-logo.png"
                      alt="Image not found !"
                    />
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon flaticon-menu flat-small text-primary"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav ms-auto">
                      <li className="nav-item">
                        <a className="nav-link nav-link-style" href="/">
                          Home
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link nav-link-style" href="#contact-us">
                          Contact Us
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link nav-link-style" href="#gallery">
                          Gallery
                        </a>
                      </li>
                      <li className="nav-item dropdown">
                        <a className="nav-link nav-link-style dropdown-toggle" href="#">
                          Our estates
                        </a>
                        <ul className="dropdown-menu">
                          {estates.map((estate: any, index) => (
                            <li key={estate.id || index}>
                              <a className="dropdown-item" href={`/estates/${estate.id}`}>
                                {estate.name || 'Estate'}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                    <a
                      href="/affiliate-program"
                      className="btn btn-white add-listing-btn nav-link-style"
                      style={{
                        color: "#510000",
                        marginLeft: "1rem",
                        borderRadius: "60px",
                        border: "1px solid #510000",
                      }}
                    >
                      Become an affiliate
                    </a>
                    <a
                      href="/buy-land"
                      className="btn btn-white add-listing-btn nav-link-style"
                      style={{
                        marginLeft: "1rem",
                        borderRadius: "60px",
                        color: "#fff",
                        backgroundColor: "#510000",
                      }}
                    >
                      Buy Now
                    </a>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!--============== Header Section End ==============--> */}

      {/* {showMobileMenu && <MobileMenuItems />} */}
    </>
  );
}

export default Header;
