import './our-leadership.css'
function OurLeadership() {
    return (
        <><div className="full-row bg-light">
            <div className="container">
                <div className="row">
                    <div className='our-leadership'>
                        <div>
                            <img
                                src="assets/images/boss-man.png"
                                className='img'
                                alt="Image Not Found!"
                            />
                        </div>
                        <div>
                            <div className="row">
                                <div className="col">
                                    <p
                                        className="sub-header-title-text"
                                    >
                                        Meet The founder
                                    </p>
                                    <h2 className="w-100 mx-auto w-sm-100 header-title-text">
                                        We bank the future
                                    </h2>
                                </div>
                            </div>
                            <p className='description-text'
                            >
                                Chimezie M. Okeke is an award-winning real estate entrepreneur and the visionary Founder and CEO of CADLANDS Real Estate Limited, one of Nigeria’s fastest-growing property development firms. Under his leadership, CADLANDS has developed several estates, including Prime City Estate, Genesis City, The Asset City, etc., providing secure, high-value land investments across Anambra and Abuja.<br />
                                Driven by a mission to transform Nigeria’s real estate sector, Chimezie has made transparency, ease of acquisition, and due diligence the cornerstones of CADLANDS. Thanks to his commitment, clients can confidently invest in land without fear of fraud or uncertainty, a refreshing change in an industry plagued by distrust. In half a decade, he has propelled CADLANDS into a multimillion-naira enterprise, earning a reputation for integrity and excellence. Outside of real estate, Chimezie is a passionate advocate for creativity and youth empowerment.<br />
                                He founded Road to Fame Nigeria (RTFN), a widely celebrated talent platform, and later launched Cadplay (www.CADPLAY.tv), a digital hub designed to showcase and support Nigeria’s emerging filmmakers and storytellers. Despite his achievements, Chimezie does not take himself too seriously, as he occasionally takes up MC and compère duties just to remind people that he is a man of many talents, and real estate moguls can have a sense of humour too!<br />
                                A proud indigene of Ozubulu, Anambra State, Chimezie holds a BSc in Applied Biochemistry from Nnamdi Azikiwe University, Awka.<br />
                                His contributions to business, youth development, and professional excellence have earned him multiple awards, including:<br />
                                ✔ ASES Award for Contribution Towards Student Welfare<br />
                                ✔ Rotary Club Professional Excellence Service Award<br />
                                ✔ South Eastern Campus Award (SECA) for Youth Development<br />
                                ✔ 100 Achievers Award<br />
                                ✔ Top 50 Anambra Businessmen Award
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div></>
    )
}

export default OurLeadership