import './footer.css'

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      {/* <!--============== Footer Section Start ==============--> */}
      <footer
        className="full-row footer-default-dark bg-footer"
        style={{ paddingBottom: "30px" }}
      >
        <div className="container">
          <div className='row logo-container'>
            <div className="col">
              <a href="/">
                <img
                  src="assets/images/logo/cadland-logo.png"
                  className='footer-logo'
                  alt="Image not found!"
                />
              </a>
            </div>

            <div className="col media-widget social-icon-container">
              <a href="#">
                <img
                  className="social-icon"
                  src="assets/images/icon/facebook-icon.png"
                  alt="Image not found!"
                />
              </a>
              <a href="#">
                <img
                  className="social-icon"
                  src="assets/images/icon/x-icon.png"
                  alt="Image not found!"
                />
              </a>
              <a href="#">
                <img
                  className="social-icon"
                  src="assets/images/icon/instagram-icon.png"
                  alt="Image not found!"
                />
              </a>
            </div>
          </div>


          <div className="nav-link-container">
            <a href='/#about-us' className='nav-link-item'>About us</a>
            <a href='/#service' className='nav-link-item'>Services</a>
            <a href='/#contact-us' className='nav-link-item'>Contact us</a>
            <a className='nav-link-item'>Privacy Policy</a>
            <a className='nav-link-item'>Terms of Usage</a>
          </div>

        </div>
      </footer>
      <div className="rights-container">
        <p className='rights-item'>© 2025 Cadlands. All rights reserved.</p>
      </div>

      {/* <!-- Scroll to top --> */}
      <div className="scroll-top-vertical xs-mx-none" id="scroll">
        Go Top <i className="ms-2 fa-solid fa-arrow-right-long"></i>
      </div>
      {/* <!-- End Scroll To top --> */}
    </>
  );
}

export default Footer;
