import './contact-us.css'
function ContactUs() {
    return (
        <div id="contact-us" className="full-row bg-primary" style={{ padding: "0px" }}>
            <div className="">
                <div className="row">
                    <div className=''>
                        <div
                            className="row contact-us-grid"
                        >
                            <div className='contact-us-text'>
                                <p className="text-start contact-us-sub-header-text">
                                    CONTACT US
                                </p>
                                <h2
                                    className="down-line w-100 mx-auto mb-4 text-start w-sm-100 header-text contact-us-header-text"
                                >
                                    Let us handle your <br /> Real Estate investments
                                </h2>

                                <div className="mb-5">
                                    <span className="d-table w-50 w-sm-100 text-start contact-us-item-title-text">
                                        Call on
                                    </span>
                                    <span className="d-table w-100 w-sm-100 sub-title general-font text-start contact-us-item-description-text">
                                        Call our team Monday - Sunday, round the clock
                                    </span>
                                    <span className="d-table w-100 w-sm-100 sub-title general-font text-start contact-us-item-description-text">
                                        <img
                                            src="assets/images/testimonials/phone-icon.png"
                                            style={{ marginRight: 5, height: "20px", width: "20px"}}
                                            alt="Image Not Found!" />
                                        +234 912 222 3333, +234 91-2222-6666, +234 912-666-6666
                                    </span>

                                    <span className="d-table w-50 w-sm-100 text-start contact-us-item-title-text">
                                        Chat with us
                                    </span>
                                    <span className="d-table w-50 w-sm-100 text-start contact-us-item-description-text">
                                        Speak to our friendly team
                                    </span>
                                    <span
                                        className="d-table w-100 w-sm-100 text-start contact-us-item-description-text"
                                    >
                                        <img
                                            src="assets/images/testimonials/mail-icon.png"
                                            style={{ marginRight: 5, height: "20px", width: "20px" }}
                                            alt="Image Not Found!" />
                                        Send us a mail
                                    </span>
                                    <span
                                        className="d-table w-100 w-sm-100 text-start contact-us-item-description-text"
                                    >
                                        <img
                                            src="assets/images/testimonials/twitter-x-icon.png"
                                            style={{ marginRight: 5, height: "20px", width: "20px" }}
                                            alt="Image Not Found!" />
                                        Message us on X
                                    </span>

                                    <span
                                        className="d-table w-50 w-sm-100 text-start contact-us-item-title-text"
                                    >
                                        Visit us
                                    </span>
                                    <span
                                        className="d-table w-50 w-sm-100 text-start contact-us-item-description-text"
                                    >
                                        Come see us in person at
                                    </span>
                                    <span
                                        className="d-table w-100 w-sm-100 text-start contact-us-item-description-text"
                                    >
                                        <img
                                            src="assets/images/testimonials/location-icon.png"
                                            style={{ marginRight: 5, height: "20px", width: "20px" }}
                                            alt="Image Not Found!" />
                                        27A Tunis street, Wuse Zone 6, Abuja
                                    </span>

                                    <span
                                        className="d-table w-50 w-sm-100 text-start contact-us-item-title-text"
                                    >
                                        Send Us a message
                                    </span>

                                    <div className='contact-us-form'>
                                        <span className="d-table w-50 w-sm-100 text-start mt-2">
                                            <input
                                                className="form-control"
                                                placeholder="Email Address"
                                                type="text"
                                                disabled
                                            />
                                        </span>

                                        <span
                                            className="d-table w-50 w-sm-100 text-start mt-2"
                                        >
                                            <textarea
                                                className="form-control"
                                                placeholder="Type Message"
                                                rows={3}
                                                disabled
                                            ></textarea>
                                        </span>
                                        <span
                                            className="d-table w-50 w-sm-100 text-start mt-2"
                                        >
                                            <a
                                                className="btn btn-white add-listing-btn w-100"
                                                style={{
                                                    borderRadius: "60px",
                                                    border: "1px solid #510000",
                                                    color: "#510000"
                                                }}
                                            >
                                                Send Us a Message
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div
                                className='contact-us-img-container'
                            >
                                <img
                                    src="assets/images/testimonials/contct-us-img-3.png"
                                    alt="Image Not Found!"
                                    style={{ float: "right", width: "100% !important", height: "auto" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs