import './our-commitment.css'
function OurCommitment() {
    return (
        <><div className="full-row bg-light">
            <div className="container">
                <div className="row">
                    <div className="col mb-5">
                        <p className="text-center our-commitment-sub-title-text">
                            Our Commitment
                        </p>
                        <h2 className="down-line w-85 mx-auto mb-4 text-center w-sm-100 title-text">
                            Every piece of <span className="text-primary">property</span> holds a promise, and every promise begins with you
                        </h2>
                    </div>
                </div>
                <div className="row row-cols-lg-5 row-cols-sm-4 row-cols-1 g-3 justify-content-center">
                    <div className="col item-card">
                        <a
                            href="#"
                            className="text-center d-flex flex-column align-items-center py-5 p-4 bg-white item-card transation h-100"
                        >
                            <div className="icon position-relative">
                                <img
                                    src="assets/images/icon/our-commitment-1.png"
                                    alt="Image Not Found!"
                                />
                            </div>
                            <h6 className="d-block item-header-text">
                                Peace
                            </h6>
                            <p className='description-text'>
                                We believe that genuine property ownership involves more than just acquiring land or a building; it encompasses security, stability, and peace of mind.
                            </p>
                        </a>
                    </div>
                    <div className="col item-card">
                        <a
                            href="#"
                            className="text-center d-flex flex-column align-items-center py-5 p-4 bg-white item-card transation h-100"
                        >
                            <div className="icon position-relative">
                                <img
                                    src="assets/images/icon/our-commitment-2.png"
                                    alt="Image Not Found!"
                                />
                            </div>
                            <h6 className="d-block item-header-text">
                                Profit
                            </h6>
                            <p className='description-text'>
                                We believe that genuine property ownership involves more than just acquiring land or a building; it encompasses security, stability, and peace of mind.
                            </p>
                        </a>
                    </div>
                    <div className="col">
                        <a
                            href="#"
                            className="text-center d-flex flex-column align-items-center py-5 p-4 bg-white item-card transation h-100"
                        >
                            <div className="icon position-relative">
                                <img
                                    src="assets/images/icon/our-commitment-3.png"
                                    alt="Image Not Found!"
                                />
                            </div>
                            <h6 className="d-block item-header-text">
                                Progress
                            </h6>
                            <p className='description-text'>
                                We believe that genuine property ownership involves more than just acquiring land or a building; it encompasses security, stability, and peace of mind.
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        </div></>
    )
}

export default OurCommitment